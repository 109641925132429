import React, {Component} from "react";
import {__} from "../../utilities/i18n";
import { Tag, ButtonGroup, Button, Intent } from "@blueprintjs/core";

//TODO rename to ScalarField
export default class ScalarField extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			value:this.props.value
		}
		window['form' + this.props._id] = this;
	}
	render()
	{
		const {field, title} = this.props;
		const {value} = this.state;
		const col1 = this.props.vertical ? "col-12 layout-label-vert" : "col-md-3  layout-label";
		const col2 = this.props.vertical ? "col-12 layout-data-vert" : "col-md-9 layout-data";
		return <div className="row dat" >
			<div className={col1}>
				{__( title )}
			</div>
			<div className={col2} style={{ position : "relative" }}>
			{
				this.props.editable ? this.isEnabled() : this.isDesabled()
					
			}
			</div>
		</div>
	}
	isEnabled()
	{
		const {field, title} = this.props;
		const {value} = this.state;
		return <input 
			autoFocus={this.props.autoFocus}
			type={this.props.type} 
			className={ this.props.className ? this.props.className : "form-control input dark" }
			value={ value ? value : ""}
			onChange={this.onChange}
		/>;
	}
	isDesabled()
	{
		const {field, title} = this.props;
		const visibled_value = this.props.visibled_value || "title";
		const {value} = this.state;
		return <div className="px-0 mb-1">
		{
			this.props.value 
				?
					visibled_value == field
						?
						<div className="lead">{ this.props.value + " "}</div>
						: 
						<Tag minimal={true} className="m-1">
							{ this.props.value + " " }
						</Tag>
					
				:
				null
		}
		</div>
	}
	onChange = evt =>
	{
		this.setState({value:evt.currentTarget.value});
		this.on(evt.currentTarget.value)
	}
	
	on = value =>
	{
		this.props.on( value, this.props.field, this.props.title );
	}
}