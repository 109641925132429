import React, {Component, Fragment} from "react";
import CategoryForm from "../DataTable/CategoryForm";
import { Tab, Tabs, ButtonGroup, Button, Intent } from "@blueprintjs/core";
import {sprintf} from "../../layouts/utilities/sprintf";
import {compose} from "recompose";
import { Query, withApollo } from "react-apollo";
import {withRouter} from "react-router";

import FieldInput, {addEmpty, String, Link, ExtendedLink, DateTime, RGB, Geo, MultiSelect, Checkbox, Phone, Array, Boolean} from "../../layouts/FieldInput";
import {__} from "../../layouts/utilities/i18n";
import { getQueryName, getQueryArgs, queryCollection} from "../../layouts/schema";
import { getFields} from "../../layouts/schema/ecosystem";

class RecieverForm extends Component
{
	state = {}
	constructor(props) {
		super(props);
		this.state = {
			currentTab : "rx",
			...this.props,
			
			themes : this.props.data.themes ? [...this.props.data.themes] : [],
			startthemes:this.props.data.themes ? [...this.props.data.themes] : [],
			addedthemesID:[],
			addedthemesList:null,
			lastthemesID:1,
			
			activate_sources : this.props.data.activate_sources ? [...this.props.data.activate_sources] : [],
			startactivate_sources:this.props.data.activate_sources ? [...this.props.data.activate_sources] : [],
			addedactivate_sourcesID:[],
			addedactivate_sourcesList:null,
			lastactivate_sourcesID:1,
			
			addedList:{ themes:[], activate_sources:[] }
		};
	}
	render()
	{
		
		const __themes = this.getExtTabs();	
		let rdata = {};
		const state = {...this.state};
		const apollo_fields1 = getFields(this.props.data_type);
		for(var i in apollo_fields1)
		{
			rdata[i] = state[i];
		}		
		return <div className="category_card">
			<Tabs 
				id="TabsExample" 
				onChange={this.handleTabChange} 
				animate={true} 
				vertical={true} 
				className="text-right"
				selectedTabId={this.state.currentTab}
			>
				<Tab 
					id="rx" 
					key={0}
					title={__(this.props.data_type)} 
					panel={<CategoryForm 
						{ ...this.props }						
						data={ rdata }	
						isHiddenSave={ true }
						isHiddenClose={ true }
						isHiddenDelete={true}
						isMain={ true }
						vertical={ true }					
						on={ this.onChange }
						addList ={ this.state.addedList }
					/>} 
				/>
				{__themes}	
			</Tabs>
			<div className="row">
				<div className="col-md-3">
				
				</div>
				<div className="col-md-7 ">
					<ButtonGroup className="p-2 tex-right">
						<Button
							text={__("Save")}
							onClick={this.onSave}
						/>
						
						<Button
							text={__("Close")}
							onClick={this.props.onClose}
						/>
					</ButtonGroup>
				</div>
			</div>			
		</div>
	}
	handleTabChange = currentTab =>
	{
		this.setState({ currentTab });
	}

	onSave = () =>
	{		
		//let state = {...this.state};
		//delete state._id;
		//delete state.__typename;
		console.group('CategoryForm.onSave');
		let state = {};
		console.log(this.state);
		//return;
		const _fields = getFields(this.props.data_type );
		for( let field in _fields )
		{
			if(_fields[field].type)
			{
				if(!_fields[field].editable) {
					continue;
				}
				if(_fields[field].type == "external")
				{
					console.log(field, this.state[ field ]);
					state[ field + "_id" ] = this.state[ field ] ? this.state[ field ]._id : null;
				}
				else if(_fields[field].type == "array" && _fields[field].component != "string" && !_fields[field].recieved)
				{
					console.log(field, this.state[ field + "_ids"  ], this.state[ field ]);
					state[ field + "_ids" ] = this.state[ field ] ? this.state[ field ].map(e => e._id) : [];
				}
				else if (_fields[field].recieved)
				{
					state[ field ] = this.state[ field ];
					state[ field ] = state[ field ].map(el=>{delete el.__typename; delete el.isNew; delete el.color; return el;});
				}
				else
				{
					console.log(field, this.state[ field ]);
					state[ field ] = this.state[ field ];
				}
			}
			else
			{
				console.log(field, this.state[ field ]);
				state[ field ] = this.state[ field ];
			}
		}
		
		//console.log(_fields);
		console.log(state);
		//console.log(this.props.ID);
		console.groupEnd();
		this.props.onSave( state, this.props.ID );
	}
	/*
	
	*/
	addNewTheme = evt =>
	{
		const data_type 		= evt.currentTarget.getAttribute("data_type");
		const scheme 			= this.getElementScheme(data_type);
		let themes  			= this.state[data_type].splice(0); 	// now selected Objects of data_type
		//const addList 			= "added" + data_type + "List";		// new created Objects list
		const listID			= "added" + data_type + "ID";		// 
		const lastIDfield 		= "last" + data_type + "ID";		// last free ID for add new Object 
		let addedthemesList  	= this.state.addedList[data_type] ? this.state.addedList[data_type].splice(0) : [];
		const id = this.state[ lastIDfield ] ? this.state[ lastIDfield ] : 1;
		// add empty element
		let elem  = addEmpty(scheme, {title:"New Element"});
		elem.isNew = true;		
		elem.color = "#777";
		elem.__typename = scheme;
		themes.push( elem );
		addedthemesList.push(elem);
		let addedthemesID = this.state[listID].slice(0);
		addedthemesID.push(elem);
		//console.log( this.state.addedthemesID.slice(0), addedthemesID );
		let state = { addedList:{} };
		state[ listID ] 			= addedthemesID;
		state.addedList[ data_type ] 	= addedthemesList;
		state[ data_type ] 			= themes;
		state[ lastIDfield ]		= id + 1;
		this.setState( state );
		console.log( data_type, state[ data_type ] );
		this.props.onChange( data_type, themes, id);
	}
	onThemeChange = (value, field, id) =>
	{
		//console.log( this.state );
		//console.group('RecieverForm.onThemeChange');
		let ii = -1;
		let themes = this.state.themes.slice(0);
		let theme = themes
			.filter((e, i) => {
				if(e._id == id) ii = i ;
				return e._id == id
			})[0];
		themes[ii][field] = value;
		//console.log( ii );
		//console.log( field, id, value );
		//console.log( themes );
		//console.log( this.state.themes );
		//console.groupEnd();
		this.setState({themes});
		this.props.onChange( "themes", value, id);
		
		
	}
	onChange = (value, field, id) =>
	{
		const {data_type} = this.props;
		let obj ={ addedList : {} };
		const component 		= this.getElementScheme(field);
		
		// если дочерний редактируемый элемент
		const apollo_fields1 = getFields(this.props.data_type);
		const query_name= getQueryName(this.props.data_type);
		const query_args= getQueryArgs(this.props.data_type);
		if( apollo_fields1[field].recieved )
		{
			const startthemes		= "start" + field;
			const addedthemesID		= "added" + field + "ID";			
			const apollo_query		= query_name;
			// let fields 				= Object.keys(layouts.schema[component].apollo_fields).join(" ");
			const query				= queryCollection(null, query_name, query_args);
			const cashe_themes 		= this.props.client.cache.readQuery({ query });
			const listID			= "added" + data_type + "ID";
			const themes 			= cashe_themes[query_name].concat( this.state[addedthemesID].slice(0) );
			console.log( query_name, themes );
			let new_themes, full_themes;
			// add to new themes label isNew
			full_themes = themes.map(e =>
			{
				const isNew = this.state[ startthemes ].filter( el => el._id != e._id ).length > 0;
				//console.log(isNew, e.title);
				//if(isNew)
					e.isNew = isNew;
				return e;
			});
			new_themes		= full_themes.filter(  e => value.filter(ee => ee._id == e._id).length > 0 )
			obj[ field ]	= new_themes;
			console.log( apollo_query, new_themes );
			// new Dropdawn full list.
			obj.addedList[ field ] 	= themes;
			this.props.onChange( field, new_themes, id);
		}
		else 
		{
			obj[ field ] = value;
			this.props.onChange( field, value, id);
		}
		console.log(value, field, id, obj);
		this.setState( obj );
	}	
	getElementScheme(field)
	{
		const my_apollo_fields 	= getFields(this.props.data_type);
		const my_field 			= my_apollo_fields[field];
		return my_field.component;
	}
	getExtTabs()
	{
		const apollo_fields1 = getFields(this.props.data_type);
		const apollo_fields = Object.keys(apollo_fields1);
		const recieved = apollo_fields.filter(field => apollo_fields1[field].recieved);
		//console.log(recieved);
		let arr = [];
		recieved.forEach((res, n) =>
		{			
			arr.push( 
				<div className="tab-descr devider-top is-new" key={n * 1000} >
					{__(apollo_fields1[ res ].title)}
				</div> 
			);
			this.state[res].forEach((e, i) =>
			{
				//console.log(n * 1000 + 100 + i)
				const tb = <Tab
					key={ n * 1000 + 100 + i }
					id={ res + e._id } 
					className={e.isNew ? "is-new" : "not-new"}
					title={ e.title }
					panel={<Fragment>
						<div className="small is-new hidden">{e._id}</div>
						<div className="text-danger font-weight-light text-center">
						{
							e.isNew 
								? 
								sprintf(
									__( "this %s no added to this %s yet." ), 
									__( e.__typename ), 
									__( this.props.data_type )
								)
								: 
								"..."
						}
						</div>
						<CategoryForm 					
							{...e}						
							data={e}	
							ID={e._id}
							isHiddenSave={true}
							isHiddenDelete={true}
							intent={Intent.PRIMARY}
							isHiddenClose={true}
							data_type="Theme"
							on={ this.onThemeChange }					
							vertical={ true }	
						/>
					</Fragment>} 
				/>
				//console.log( tb );
				arr.push( tb );
			});		
			arr.push( <div className="tab-descr" key={n * 1000 + 1} >
				<Button 
					icon="plus" 
					minimal={true} 
					fill={true} 
					intent={Intent.DANGER} 
					className="bp3-btn-sm flex-sm-right flex-xm-center pr-3"
					data_type={res}
					onClick={this.addNewTheme}
				/>
			</div>);				
		});
		//console.log(arr);
		return arr;
	}		
}

export default compose(
	withApollo,
	withRouter
)(RecieverForm);
