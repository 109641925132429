import BasicStateFunctional from "./BasicStateFunctional";
import React, { Component, Fragment, useContext, useState } from "react";
import gql from 'graphql-tag';
import { compose } from "recompose";
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from "react-apollo";
import { withRouter } from "react-router";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import FormEditState from "./FormEditState";
import {Card, Intent} from "@blueprintjs/core";
import {__} from "../layouts/utilities/i18n";
import {
	getQueryName, getQueryArgs,
	queryCollection, querySingle,
	getChangeName, getInputTypeName, getMutationArgs,
	mutationAdd, mutationEdit, mutationDelete
} from "../layouts/schema";
import {AppToaster} from "../layouts/utilities/blueUtils";
import UserContext from "../layouts/userContext";

const mutation_name =  "registerUser";
		const input_type_name = "UserInput";
		const mutation_args = getMutationArgs( "UserCurrentInput" );
		const change_mutation = gql`mutation registerUser($input: UserInput) {
			registerUser(input: $input) {
			  _id
			  __typename
			}
		  }
		  `;

const NewProfileState = function (props) {
    return <BasicStateFunctional title={"регистрация"} {...props
    } >
        <FormEditState
            data_type={"UserCurrentInput"}
			//isHiddenSave={true}
		change_mutation={change_mutation}
            isHiddenClose={true}
            isHiddenDelete={true}
            onChange={(user)=>{
                AppToaster.show({
				intent: Intent.SUCCESS,
				icon: "tick",
				message: __("User Registered")
			});
			props.history.push("/login");
		}}
        />
    </BasicStateFunctional>;
}

export default compose(

    withApollo,
    withRouter
)(NewProfileState);